import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAgeendaData, fetchSelectAgenda } from "../Redux/Slice";

function AgendaProgramme() {
  const [activeTab, setActiveTab] = useState("day_1");
  const [selectItem, setSelectItem] = useState(null);

  const dispatch = useDispatch();

  const agenda_Record = useSelector((state) => state.cms.agenda_Record);
  const selected_Record = useSelector((state) => state.cms.select_Agenda);

  useEffect(() => {
    dispatch(fetchAgeendaData());
  }, [dispatch]);

  useEffect(() => {
    if (agenda_Record.length > 0) {
      const defaultItem = agenda_Record[0];
      setActiveTab("day_1");
      setSelectItem(defaultItem);
      dispatch(fetchSelectAgenda(defaultItem._id));
    }
  }, [agenda_Record, dispatch]);

  const handleTabClick = (tab, item) => {
    setActiveTab(tab);
    setSelectItem(item);
    dispatch(fetchSelectAgenda(item._id));
  };

  return (
    <div className="page-wrapper">
      <span className="header-span"></span>

      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>CONFERENCE AGENDA</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>CONFERENCE AGENDA</li>
          </ul>
        </div>
      </section>

      <section id="agenda">
        <div className="container">
          <div className="tz_tab_custom">
            {/* Nav tabs */}
            <div className="tz_tabs_meetup">
              <ul className="navv nav-tabs" role="tablist">
                {agenda_Record.map((item, i) => (
                  <li
                    key={i}
                    role="presentation"
                    className={
                      activeTab === `day_${i + 1}` ? "active mx-4" : "mx-4"
                    }
                    onClick={() => handleTabClick(`day_${i + 1}`, item)}
                  >
                    <a role="tab" data-toggle="tab">
                      <b> {item.agenda_title}</b>
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="tab-content tz_tab_content">
              {agenda_Record.map((item, i) => (
                <div
                  key={i}
                  role="tabpanel"
                  className={`tab-pane fade ${
                    activeTab === `day_${i + 1}` ? "in active" : ""
                  }`}
                  id={`day_${i + 1}`}
                >
                  {activeTab === `day_${i + 1}` && selectItem && (
                    <div className="tz_event_meetup">
                      <div className="tz_box_event_meetup">
                        <h3>
                          <b>{selectItem?.sub_title || "No Title Available"}</b>
                        </h3>
                        <h3 className="tz_event_meetup_subtitle">
                          {selectItem?.agenda_description ||
                            "No Description Available"}
                        </h3>
                        {selected_Record.length > 0 ? (
                          selected_Record.map((item, index) => (
                            <div
                              className="tz_event_meettup_box_content"
                              key={index}
                            >
                              <div className="tz_event_meetup_content">
                                <div className="tz_meetup_box_detail">
                                  <div className="tz_meetup_box_detail_custom">
                                    <span className="tz_meetup_start_time">
                                      {item.start_time || "No Time Available"}
                                    </span>
                                    <h4>
                                      <b>
                                        {item.programmes_title ||
                                          "No Title Available"}
                                      </b>
                                    </h4>
                                    <p className="tz_event_time">
                                      <i className="fa fa-clock-o"></i>
                                      {item.start_time} - {item.end_time}
                                    </p>
                                    <div className="tz_event_meetup_item_content">
                                      {/* <p>{item.description || 'No Description Available'}</p> */}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.description
                                            ? item.description.replace(
                                                /,/g,
                                                "<br />"
                                              )
                                            : "No Description Available",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No records available for this day.</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AgendaProgramme;
