import React, { useEffect, useState } from 'react';
import { Link,useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import ReactGA from 'react-ga';
import {
  fetchCmsPages3,
  fetchCmsPages,
  fetchCmsPages2,
  fetchSpeakerData,
} from "../../Redux/Slice";
const Speakers = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  const Record = useSelector((state) => state.cms.speaker_Record.data);

  const speaker_Record = Record?.filter((i)=>!i.is_dignitaries)

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    dispatch(fetchSpeakerData());
   
      // console.log("Fetching CMS page with slug:", slug);
      dispatch(fetchCmsPages());
      dispatch(fetchSpeakerData());
      dispatch(fetchCmsPages2());
      // dispatch(fetchCmsPages3(slug));
    
  }, [dispatch]);

  useEffect(() => {
    if (isLoaded) {
      const preloader = document.querySelector('.preloader');
      if (preloader) {
        preloader.style.display = 'none';
      }
    }
  }, [isLoaded]);

  return (
    <div className="page-wrapper">
      {/* <div className="preloader">
        <div className="loader"></div>
      </div> */}
      <span className="header-span"></span>
     
      <section className="page-title" style={{ backgroundImage: 'url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)' }}>
        <div className="auto-container">
          <h1>Speakers</h1>
          <ul className="bread-crumb clearfix">
            <li><Link to="/">Home</Link></li>
            <li>Speakers</li>
          </ul>
        </div>
      </section>
      <section className="speakers-section-three">
        <div className="auto-container p-2">
          {/* <div className="sec-title text-center">
            <h2>Speakers</h2>
          </div> */}
          <div className="row">
            {speaker_Record &&
              speaker_Record.map((user, i) => (
                <div
                  key={i}
                  className="speaker-block-three col-xl-4 col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                >
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <a href="speakers-detail.html">
                          <img
                             src={`https://d1iakh2uzy2hor.cloudfront.net/speaker/${user.photo}`}
                            alt={user.name}
                            loading='lazy'
                          />
                        </a>
                      </figure>
                    </div>
                    <div className="info-box">
                      <h4 className="name">
                        <a>{user.name}</a>
                      </h4>
                      <span className="designation">{user.designation}</span>

                      <span className="designation">{user.sub_title}</span>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
          </div>
         
        </div>
      </section>
      <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-angle-double-up"></span></div>
    </div>
  );
};

export default Speakers;
