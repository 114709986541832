import {useEffect} from 'react'
import ReactGA from 'react-ga';
import { useParams,useLocation } from "react-router-dom";
function Coc() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
    const backgroundImageStyle = {
        backgroundImage: 'url(images/background/02.jpg)',
      };
  return (
    <div className='page-wrapper'>
           <span className="header-span"></span>
        <section class="page-title" style={backgroundImageStyle}>
        <div class="auto-container">
          <h1>Convention Organizing Committee&nbsp;(COC)</h1>
          <ul class="bread-crumb clearfix">
            <li><a href="/">Home</a></li>
            <li>Convention Organizing Committee&nbsp;(COC)</li>
          </ul>
        </div>
      </section> 
    <div classname="page-wrapper">
    <div className="table-container m-auto">
    <table className="table table-bordered">
        <thead>
          <tr>
            <th colspan="3" classname="title text-center">FHRAI CONVENTION, 2024</th>
          </tr>
          <tr>
            <th>Sr. No.</th>
            <th>DETAILS</th>
            <th>STATUS / PROGRESS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td colspan="2" classname="header">CHAIRMAN OF THE CONVENTION COMMITTEE (CCC) &amp; CONVENTION ORGANISING COMMITTEE (COC)</td>
          </tr>
          <tr>
            <td>1</td>
            <td>CHAIRMAN</td>
            <td>MR. D. S. ADVANI</td>
          </tr>
          <tr>
            <td>2</td>
            <td>CO- CHAIRMAN OF THE CONVENTION</td>
            <td>MR. CHETAN MEHTA</td>
          </tr>
          <tr>
            <td>3</td>
            <td>CO- CHAIRMAN OF THE CONVENTION</td>
            <td>MR. VINAY ALBURQUEQUE</td>
          </tr>
          <tr>
            <td>4</td>
            <td>MEMBER</td>
            <td>MR. SANDEEP TALAULICAR</td>
          </tr>
          <tr>
            <td>5</td>
            <td>MEMBER</td>
            <td>MR. GURBIR SINGH BEDI</td>
          </tr>
          <tr>
            <td>6</td>
            <td>MEMBER</td>
            <td>MR. RISHI MEHRA</td>
          </tr>
          <tr>
            <td>7</td>
            <td>MEMBER</td>
            <td>MR. AMRISH ARORA</td>
          </tr>
          <tr>
            <td>8</td>
            <td>MEMBER</td>
            <td>MR. CARL COSTA</td>
          </tr>
          <tr>
            <td>9</td>
            <td>MEMBER</td>
            <td>MR. NAYEEM SAIT</td>
          </tr>
          <tr>
            <td>10</td>
            <td>SPECIAL ADVISOR</td>
            <td>MR. JIMMY SHAW</td>
          </tr>
          <tr>
            <td>11</td>
            <td>SPECIAL ADVISOR</td>
            <td>MR. GURBAXISH SINGH KOHLI</td>
          </tr>
          <tr>
            <td>12</td>
            <td>SPECIAL ADVISOR</td>
            <td>MR. TALJINDER SINGH</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
    </div>
  )
}

export default Coc
