import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHoteldata } from "../../Redux/Slice";
import { Link, useLocation } from "react-router-dom";
function Hotels() {
  const dispatch = useDispatch();
  const hotels_Record = useSelector((state) => state.cms.hotels_Record);

  useEffect(() => {
    dispatch(fetchHoteldata());
  }, [dispatch]);

  console.log("Hotels Record >>>>", hotels_Record);

  return (
    <div className="page-wrapper">
      {/* <div className="preloader">
        <div className="loader"></div>
      </div> */}
      <span className="header-span"></span>

      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>Hotel Convention Charges</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Hotel Convention Charges</li>
          </ul>
        </div>
      </section>

      {/* Hotel Table Section */}
      <section className="container m-5 hotel-table-section">
        <div className="table-responsive">
          {/* <table className="table table-bordered table-hover">
            <thead className="thead-light">
              <tr>
                <th>Hotel Image</th>
                
                <th>Hotel Name</th>
                <th> Room Price </th>
                <th>Distance (from event)</th>
              </tr>
            </thead>
            <tbody>
              {hotels_Record?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <img
                      src={
                        "https://d1iakh2uzy2hor.cloudfront.net/" + item.photo
                      }
                      alt={item.name}
                      className="img-fluid"
                      style={{ width: "150px" }}
                    />
                  </td>
                
                  <td>{item.name}</td>
                  <td>
                    {" "}
                    <small>
                      <i className="fa fa-rupee"></i>
                    </small>{" "}
                    {item.single_room_prise} /-
                  </td>
               
                  <td>{item.distance_details}</td>
                </tr>
              ))}
            </tbody>
          </table> */}

<table class="table table-bordered table-striped">
        <thead class="table-light">
            <tr>
                <th>S No.</th>
                <th>Hotels</th>
                <th>Single/Double Room Rate</th>
                <th>Distance From Convention Venue</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>Taj Cidade de Goa - Horizon</td>
                <td>14000 + GST 18%</td>
                <td>0 KM</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Taj Cidade de Goa - Heritage</td>
                <td>10500 + GST 18%</td>
                <td>0.5 KM</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Vivanta Panjim</td>
                <td>7450 + GST 12%</td>
                <td>7 KM</td>
            </tr>
            <tr>
                <td>4</td>
                <td>Vivanta Miramar</td>
                <td>7450 + GST 12%</td>
                <td>2.9 KM</td>
            </tr>
            <tr>
                <td>5</td>
                <td>Ginger Dona Paula</td>
                <td>4800 + GST 12%</td>
                <td>0.7 KM</td>
            </tr>
            <tr>
                <td>6</td>
                <td>Ginger Panjim</td>
                <td>4800 + GST 12%</td>
                <td>10.9 KM</td>
            </tr>
        </tbody>
    </table>
        </div>
      </section>
      <div class="w-100 h-auto">
        <img
          src="https://d1iakh2uzy2hor.cloudfront.net/file-1725882286554.jpeg"
          class="w-100 h-auto"
        />
      </div>
    </div>
  );
}

export default Hotels;
