import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./travel.css";
import { fetchVisitordata } from "../../Redux/Slice";

function ReserveHotel() {
  const dispatch = useDispatch();
  const isVisitor_Record = useSelector((state) => state.cms.isVisitor_Record);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSearchPerformed(true);
    dispatch(fetchVisitordata(barcodeValue));
    setBarcodeValue("");
  };

  useEffect(() => {
    if (searchPerformed) {
      if (isVisitor_Record.length > 0) {
        if (isVisitor_Record[0]?.form_data.paymentstatus === "Paid") {
          const record = isVisitor_Record[0];
          toast.success("Success: Data found!");

          const targetUrl = `https://admin.expoplan.in/traveler/static-form-visitor/${record?._id}`;
          
          // Redirect to the external URL

          const newWindow = window.open(
            targetUrl,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) {
            newWindow.focus(); // Focus the newly opened window
          }

          // window.location.href = targetUrl;
        } else {
          toast.error("Oops! Your Payment is Pending.");
        }
      } else if (isVisitor_Record.length === 0) {
        toast.error("Oops! Record not found.");
        setSearchPerformed(false);
      }
    }
  }, [isVisitor_Record, searchPerformed]);

  const handleInputChange = (event) => {
    setBarcodeValue(event.target.value);
  };

  return (
    <div className="page-wrapper">
      <span className="header-span"></span>
      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>Reserved Hotel</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Reserved Hotel</li>
          </ul>
        </div>
      </section>

      <section className="contact-page-section">
        <div className="auto-container">
          <div className="form-column col-lg-8 col-md-12 col-sm-12 shadow-box">
            <h2> Enter your Registration No.</h2>
            <form className="barcode-search-form" onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Enter registration number"
                name="barcode"
                className="barcode-input"
                value={barcodeValue}
                onChange={handleInputChange}
              />
              <button type="submit" className="search-button">
                Search
              </button>
            </form>
          </div>
        </div>
      </section>

      <ToastContainer />
    </div>
  );
}

export default ReserveHotel;
