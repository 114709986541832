import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./header.css";
import { IoClose, IoMenu, IoChevronDown } from "react-icons/io5"; // Import necessary icons
import "./header.css";
import {
  fetchCmsPages,
  fetchCmsPages2,
  fetchCmsPages3,
} from "../../Redux/Slice"; // Adjust the path as necessary

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const cmsStatus = useSelector((state) => state.cms.status);
  const cmsPages = useSelector((state) => state.cms.pages.data);
  const cmsPages2 = useSelector((state) => state.cms.pages2.data);
  const [slugurl, setSlugurl] = useState("");

  const Params = useParams();
  const { slug } = Params;

  // const toggleMobileMenu = () => {
  //   setIsOpen(!isOpen); // Update state to toggle menu open/closed
  // };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {  // Adjust this value for when you want the background to change
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let data = localStorage.getItem("slug");
    setSlugurl(location.pathname);
    if (cmsStatus === "idle") {
      dispatch(fetchCmsPages());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(slug));
    }
  }, [location, cmsStatus, dispatch, slugurl]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === '/register') {
      window.location.href = 'https://admin.fhraiconvention.com/static-visitor?org_id=663f4428c311bd0e45312486&exb_id=663f4578c311bd0e453126b0&form_id=663f457bc311bd0e45312c37&form=web';
    }
  }, []);

  const handleLinkClick = (url) => {
    const isExternalLink = /^https?:\/\//.test(url);
    setIsMobileMenuOpen(false);
    setIsOpen(false)
    if (isExternalLink) {
      window.open(url, "_self");
    } else {
      localStorage.setItem("slug_url", url);
      navigate(`/${url}`);
    }
  };

  const sortedCmsPages2 = cmsPages2
    ? [...cmsPages2].sort((a, b) => {
        if (a.page_title === "About Convention") return -1;
        if (b.page_title === "About Convention") return 1;
        return a.page_title.localeCompare(b.page_title);
      })
    : [];

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  if (cmsStatus === "loading") return <div className="preloader"></div>;

  const isHomePage = slugurl === "/home" || slugurl === "/";

  // const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen); // Toggle dropdown state


  return (
    <div className="page-wrapper">
      <header className="main-header header-style-two alternate-two">
      <div className={`main-box ${isScrolled ? 'scrolled' : ''}`}>
          {!isMobileMenuOpen && (
            <div className="auto-container clearfix">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/home">
                    <img
                      src="https://www.fhraiconvention.com/fhrai-logo.png"
                      width="100%"
                    
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
              </div>

              <div className="main-menu nav-outer clearfix">
                <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <span className="icon flaticon-menu"></span>
                </div>

                <nav className="navbar-expand-md navbar-light">
                  <div className="navbar-header">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={toggleMobileMenu}
                    >
                      <span
                        className="icon flaticon-menu-button"
                        style={{ color: "pink" }}
                      ></span>
                    </button>
                  </div>

                  <div
                    className="collapse navbar-collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix ">
                      {cmsPages &&
                        cmsPages.map((page, index) => {
                          const subPages = sortedCmsPages2.filter(
                            (subPage) => subPage.parent_id === page._id
                          );
                          const hasSubPages = subPages.length > 0;
                          return (
                            <li
                              key={index}
                              className={hasSubPages ? "dropdown" : ""}
                            >
                              <a
                                className={`${isHomePage ? `${isScrolled ? 'text-dark' : ''}` : "text-dark"}`}
                                onClick={() => handleLinkClick(page.slug_url)}
                              >
                                {page.page_name}
                              </a>
                              {hasSubPages && (
                                <ul>
                                  {subPages.map((subPage, subIndex) => (
                                    <li key={subIndex}>
                                      <a
                                        onClick={() =>
                                          handleLinkClick(subPage.slug_url)
                                        }
                                      >
                                        {subPage.page_title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          )}
        </div>
      </header>

      {isMobileMenuOpen && (
        <header className="header headers">
          <div className="d-flex justify-content-between p-3 m-3">
            <div className="nav-logo">
              <Link to="/home" onClick={toggleMobileMenu}>
                <img
                  src="https://www.fhraiconvention.com/cdn/images/logo-2.png"
                  alt=""
                  title=""
                  width="150px"
                />
              </Link>
            </div>
            <div className="close-btn" onClick={toggleMobileMenu}>
              <IoClose className="close-icon" />
            </div>
          </div>
          <ul className="navigation clearfix p-3">
            <div className="menu-backdrop" onClick={toggleMobileMenu}></div>

            {cmsPages &&
              cmsPages.map((page, index) => {
                const subPages = sortedCmsPages2.filter(
                  (subPage) => subPage.parent_id === page._id
                );
                const hasSubPages = subPages.length > 0;

                return (
                  <li
                    key={index}
                    className={hasSubPages ? "dropdown p-2" : "drpdwn p-2"}
                  >
                    <a
                      className={`text-warning font-weight-semibold ${
                        isHomePage ? "d-flex justify-content-between" : ""
                      }`}
                      onClick={
                        hasSubPages
                          ? (event) => {
                              event.preventDefault(); // Prevent default link click behavior
                              toggleDropdown(); // Toggle dropdown on click
                            }
                          : () => handleLinkClick(page.slug_url) // Handle routing for top-level items
                      }
                    >
                      <span>{page.page_name}</span>
                      {hasSubPages && (
                        <span>
                          {" "}
                          <IoChevronDown className="dropdown-arrow" />
                        </span>
                      )}
                    </a>
                    {hasSubPages &&
                      isOpen && ( // Conditionally render subpages only when open
                        <ul className="dropdown-content">
                          {subPages.map((subPage, subIndex) => (
                            <li key={subIndex}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault(); // Prevent default link click behavior
                                  handleLinkClick(subPage.slug_url); // Handle routing on subpage click
                                }}
                                className="text-secondary" // Submenu item color
                              >
                                {subPage.page_title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                );
              })}
          </ul>
        </header>
      )}
    </div>
  );
}

export default Header;
