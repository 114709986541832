import React, { useEffect } from "react";
import { useParams,useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCmsPages3,
  fetchCmsPages,
  fetchCmsPages2,
} from "../../Redux/Slice";

import { Helmet, HelmetProvider } from "react-helmet-async";
import HTMLParser from "react-html-parser"; // Import the parser
import "./cms.css";

const CmsPages = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const cmsStatus = useSelector((state) => state.cms.status);
  const page = useSelector((state) => state.cms.page);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  useEffect(() => {
    if (slug) {
      console.log("Fetching CMS page with slug:", slug);
      dispatch(fetchCmsPages());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (cmsStatus === "succeeded" && page) {
      // console.log("Page data fetched:", page);
      // console.log("Content body:", page.content.body);
      localStorage.setItem("content", page.content.body);
    }
  }, [cmsStatus, page]);

  if (cmsStatus === "loading") return <div className="preloader"></div>;
  if (cmsStatus === "failed") return <div>Error loading page.</div>;
  if (!page) return <div>No content available</div>;

  const { content, title, description, keywords } = page;

  let Body = localStorage.getItem("content");

  console.log("body", Body);
  return (
    <div className="page-wrapper">
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>

        <span className="header-span"></span>

        {Body && (
          <div className="">
            {typeof Body === "string" ? (
              <div dangerouslySetInnerHTML={{ __html: content.body }} />
            ) : (
              <p>Invalid Body content. Please provide a string.</p>
            )}
          </div>
        )}
      </HelmetProvider>
    </div>
  );
};

export default CmsPages;
