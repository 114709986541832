import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./footer.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  fetchCmsPages,
  fetchCmsPages2,
  fetchCmsPages3,
} from "../../Redux/Slice";
import { VERSION } from "../config";

function Footer() {
  const logos = [
    "https://www.fhraiconvention.com/cdn/images/clients/2-2.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-3.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-4.png",
    "https://www.fhraiconvention.com/cdn/images/clients/2-5.png",
    "https://d1iakh2uzy2hor.cloudfront.net/file-1721660655146.png",
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const cmsStatus = useSelector((state) => state.cms.status);
  const cmsPages = useSelector((state) => state.cms.pages.data);
  const cmsPages2 = useSelector((state) => state.cms.pages2.data);
  const [slugurl, setSlugurl] = useState("");

  useEffect(() => {
    let data = localStorage.getItem("slug");
    setSlugurl(location.pathname);
    //  console.log('data>>',data)
    if (cmsStatus === "idle") {
      dispatch(fetchCmsPages());
      dispatch(fetchCmsPages2());
      dispatch(fetchCmsPages3(slugurl));
    }
  }, [location, cmsStatus, dispatch, slugurl]);

  let data = cmsPages2
    ?.filter((item) => item.in_footer === true)
    .sort((a, b) => a.position - b.position);

  // console.log("footer", data);

  const footerLogosStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
    backgroundColor: "transparent",
  };

  const footerLogoStyle = {
    flex: 1,
    textAlign: "center",
  };

  const imgStyle = {
    maxWidth: "100%",
    height: "auto",
    display: "flex",
    margin: "0 10px",
  };
  return (
    <div className="page-wrapper">
      <footer className="main-footer style-three">
        {/* <div className="anim-icons full-width">
          <span className="icon icon-circle-blue wow fadeIn"></span>
          <span className="icon icon-dots wow fadeInleft"></span>
          <span className="icon icon-circle-1 wow zoomIn"></span>
        </div> */}

        <div className="auto-container">
          <div className="footer-content">
            <div style={footerLogosStyle}>
              {logos?.map((logo, index) => (
                <div style={footerLogoStyle} key={index}>
                  <a href="#">
                    <img
                      src={logo}
                      alt={`Logo ${index + 1}`}
                      style={imgStyle}
                    />
                  </a>
                </div>
              ))}
            </div>
            <ul className="footer-nav">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/abt-convention">About Us</Link>
              </li>
              <li>
                <Link to="/speakers">Speakers</Link>
              </li>
              <li>
                <Link to="/programme-">Schedule</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {data?.map((page, i) => (
                <li key={page.slug_url}>
                  <Link to={page.slug_url}>{page.page_name}</Link>
                </li>
              ))}

             
            </ul>
            <div className="copyright-text">
              © Copyright 2024 All Rights Reserved by{" "}
              <Link to="/">
                ExpoPlan.in - An Exhibition Management Platform
              </Link>
              <div className="text-danger">{VERSION}</div>
              {/* <ul class="social-icon-two social-icon-colored">
                <li>
                  <a href="#">
                    <i class="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-google-plus"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-pinterest"></i>
                  </a>
                </li>
              </ul> */}
            </div>

            {/* <div class="shareArticle">
              <div class="shareSocial">
               
                <ul class="socialList">
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-pinterest-p"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-solid fa-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i class="fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}

            {/* <ul className="social-icon-one">
              <li>
                <a href="#">
                  <span className="fab fa-facebook-f"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab fa-twitter"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab fa-pinterest"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fab fa-linkedin-in"></span>
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
