import { useState, useEffect } from 'react'
import { hdfcpaymentapi } from '../../Redux/Slice'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function HdfcPayForm() {
    // cost [payment ,setPayment] = useState('')

    const dispatch = useDispatch();
    const cmsStatus = useSelector((state) => state.cms.status);
    const page = useSelector((state) => state.cms.page);

    // useEffect(() => {
    //   if (slug) {

    //   }
    // }, [dispatch,]);

    if (cmsStatus === "loading") return <div className="preloader"></div>;
    // if (cmsStatus === "failed") return <div>Error loading page.</div>;
    // if (!page) return <div>No content available</div>;

    const SendPayment = () => {
        let payload = {
            "order_id": "test123",
            "amount": "1.0",
            "customer_id": "testing-customer-one",
            "customer_email": "test@mail.com",
            "customer_phone": "9876543210",
            "payment_page_client_id": "hdfcmaster",
            "action": "paymentPage",
            "return_url": "https://shop.merchant.com",
            "description": "Complete your payment",
            "first_name": "John",
            "last_name": "wick"
        }
        dispatch(hdfcpaymentapi(payload));
    }

    return (
        <div className="page-wrapper">
        <div className="preloader">
         <div className="loader"></div>
       </div>
       <span className="header-span"></span>
      
       <section className="page-title" style={{ backgroundImage: 'url(images/background/02.jpg)' }}>
         <div className="auto-container">
           <h1>Speakers</h1>
           <ul className="bread-crumb clearfix">
             <li><Link to="/">Home</Link></li>
             <li>Speakers</li>
           </ul>
         </div>
       </section>
       <section className="contact-page-section">
         <div className="auto-container">
           <div className="row clearfix">
             <div className="form-column col-lg-8 col-md-12 col-sm-12">
               <div className="inner-column">
                 <div className="contact-form">
                   <div className="sec-title">
                     <button className='btn btn-success' onClick={SendPayment}>pay </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>
 
       <div className="color-palate">
         <div className="color-trigger">
           <i className="fa fa-cog"></i>
         </div>
         <div className="color-palate-head">
           <h6>Choose Your Demo</h6>
         </div>
         <ul className="box-version option-box">
           <li>Full width</li>
           <li className="box">Boxed</li>
         </ul>
         <ul className="rtl-version option-box">
           <li>LTR Version</li>
           <li className="rtl">RTL Version</li>
         </ul>
         <div className="palate-foo">
           <span>You will find much more options for colors and styling in admin panel. This color picker is used only for demonstration purposes.</span>
         </div>
         <a href="#" className="purchase-btn">Purchase now</a>
       </div>
       <div id="search-popup" className="search-popup">
         <div className="close-search theme-btn"><span className="fas fa-window-close"></span></div>
         <div className="popup-inner">
           <div className="overlay-layer"></div>
           <div className="search-form">
             <form method="post" action="index.html">
               <div className="form-group">
                 <fieldset>
                   <input type="search" className="form-control" name="search-input" placeholder="Search Here" required />
                   <input type="submit" value="Search Now!" className="theme-btn" />
                 </fieldset>
               </div>
             </form>
             <br />
             <h3>Recent Search Keywords</h3>
             <ul className="recent-searches">
               <li><a href="#">Seo</a></li>
               <li><a href="#">Bussiness</a></li>
               <li><a href="#">Events</a></li>
               <li><a href="#">Digital</a></li>
               <li><a href="#">Conferance</a></li>
             </ul>
           </div>
         </div>
       </div>
       <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-angle-double-up"></span></div>
     </div>
    )
}

export default HdfcPayForm
