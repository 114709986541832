import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchexhibitor } from "../../Redux/Slice";
import "./directory.css"; // Assuming you will have a CSS file for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const ListingComponent = () => {
  const dispatch = useDispatch();
  const exhibitor_Record = useSelector((state) => state.cms.exhibitor_Record);

  useEffect(() => {
    dispatch(fetchexhibitor());
  }, [dispatch]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 15;

  // Search state
  const [searchTerm, setSearchTerm] = useState("");

  // Filter exhibitors based on company name
  const filteredRecords = exhibitor_Record.filter((card) =>
    card.form_data?.company_name
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  // Calculate total pages based on filtered records
  const totalPages = Math.ceil(filteredRecords.length / recordsPerPage);

  // Calculate the records to display for the current page
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredRecords.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle next/prev page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="page-wrapper">
      <span className="header-span"></span>

      <section
        className="page-title"
        style={{
          backgroundImage:
            "url(https://www.fhraiconvention.com/cdn/images/background/02.jpg)",
        }}
      >
        <div className="auto-container">
          <h1>Exhibitor Directory</h1>
          <ul className="bread-crumb clearfix">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Exhibitor Directory</li>
          </ul>
        </div>
      </section>

      <div className="grid">
        <div className="search-section">
          <h4>Search by Company Name</h4>
          <hr />
          <div className="form-group">
            <label>Company Name</label>
            <input
              type="text"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter company name"
            />
          </div>
        </div>

        {/* Displaying cards in a 4-column grid */}
        <div className="card-section">
          <div className="card-grid">
            {currentRecords.length > 0 ? (
              currentRecords.map((card) => (
                <div className="cardd" key={card._id}>
                  <div className="card-header">
                    <img
                      src={`https://d1yfhbxcvoai4q.cloudfront.net/${card.form_data["logo-uploder"]}`}
                      alt={card.form_data?.company_name}
                      className="exb_image"
                      loading="lazy"
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-details">
                      <h6>
                        <strong>{card.form_data?.company_name}</strong>
                      </h6>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No exhibitors found</p>
            )}
          </div>

          {/* Pagination */}
          <nav aria-label="Page navigation mt-5">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </li>
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>


    </div>
  );
};

export default ListingComponent;
