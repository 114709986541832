import { configureStore } from '@reduxjs/toolkit';
import cmsReducer from './Slice'; // Adjust the path as necessary

const store = configureStore({
  reducer: {
    cms: cmsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
